.bg-lime{
    color: #333333;
}

.filters p {
    cursor: pointer;
    margin-right: 10px;
    border-bottom: 2px solid transparent;
}

.filters p.active {
    color: #6523ff;
    border-bottom-color: #6523ff;
}
