/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import 'https://fonts.googleapis.com/css2?family=Roboto';
@import "helpers";
@import "loading-backdrop";


.bg-primary {
    .mat-icon {
        color: white;
    }

    .fuse-horizontal-navigation-menu-item {
        color: white;
    }
}

.mat-horizontal-stepper-content {
    margin-top: 5px;
}


.color-yellow-primary {
    color: #D9FF00 !important;
}

.custome-gray-button {
    border-color: #676767 !important;
    background-color: #676767 !important;
}


/* -------------------------------------------------------------------------- */
/*  @ Accordion
/* -------------------------------------------------------------------------- */
.mat-accordion {

    .mat-expansion-panel {
        border-radius: 1px !important;
    }
}

.mat-mdc-radio-button.cuadro {
    .mdc-form-field {
        .mdc-radio__outer-circle,
        .mdc-radio__inner-circle {
            border-radius: 0; // Deshabilita el borde redondeado
        }

        .mdc-radio__outer-circle {
            width: 16px; // Define el ancho del cuadro
            height: 16px; // Define la altura del cuadro
            border: 1px solid black; // Define el borde del cuadro
        }

        .mdc-radio__inner-circle {
            background-color: black; // Define el color de fondo del cuadro
            width: 8px; // Define el ancho del círculo interno
            height: 8px; // Define la altura del círculo interno
        }

        &.mat-mdc-radio-checked {
            .mdc-radio__inner-circle {
                background-color: white; // Define el color de fondo del círculo interno cuando está seleccionado
            }
        }
    }
}

/* -------------------------------------------------------------------------- */
/*  @ Button Toggle
/* -------------------------------------------------------------------------- */
.mat-button-toggle-group {
    &.primary{
        .mat-button-toggle {

            &.mat-button-toggle-checked {
                background-color: var(--fuse-primary);

                .mat-button-toggle-label-content {
                    @apply text-white #{'!important'};
                }
            }
        }
    }
}


.mat-mdc-paginator {
    &.items-center {
        .mat-mdc-paginator-container {
            justify-content: center;
        }

    }
}

.mat-step-label-selected {
    color: #6523FF !important;
}

.text-xxs{
    font-size: 0.5rem;
}
.mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after
{
  content:" *";
  color: red;
}

/* -------------------------------------------------------------------------- */
/*  @ Dialog
/* -------------------------------------------------------------------------- */
.cdk-overlay-pane {
    .mat-mdc-dialog-container .mdc-dialog__surface {
        border-radius: 8px !important;
        border-top: 14px solid #D9FF00 !important;
    }
}
