.spinner-focum {
    display: inline-block;
    position: relative;
    height: 80px;
    width: 80px;
}

.spinner-focum div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: inherit;
    height: inherit;
    border: 8px solid #fff;
    border-radius: 50%;
    -webkit-animation: spinner-focum 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    animation: spinner-focum 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}

.spinner-focum div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
    animation-delay: -0.45s;
}

.spinner-focum div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}

.spinner-focum div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
    animation-delay: -0.15s;
}

.backdrop {
    background-color: black;
    opacity: 0.75;
    width: inherit;
    height: inherit;
    position: absolute;
}

@keyframes spinner-focum {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
